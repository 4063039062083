import React from 'react'

import { GlobalContext } from '../providers/global'
import Utils from "../utilities/index.js"

import LoginWidget from './loginWidget'

import '../styles/page.css'
import Constants from '../constants'
import Components from './index.js'
import { withRouter } from 'react-router-dom'
import { Scrollbars } from 'react-custom-scrollbars';
import VideoModal from './videoModal'

const { Colors } = Constants
const Menu = Components.Menu



// import '../styles/menu.css'

const _NavBar = props => {

    const { state, dispatch } = React.useContext(GlobalContext)

    // console.log('in nav bar', state.user);
    return (
        <div className='pageTopBar' style={Colors.components.navBar}>
            <div className='pageHomeContainer'>
                <div className='pageNavBarBtn pageHomeBtn'>
                    <img
                        className='pageBtnImage'
                        onClick={() => {
                            props.history.push('/')
                        }}
                        src={'/logo192.png'}

                    />
                </div>
            </div>

            <div className='navBarVideoBtn'>
                <VideoModal />
            </div>

            <div className='pageLoginContainer'>
                <div className='pageNavBarBtn pageLoginBtn'>
                    <img
                        className='pageBtnImage'
                        id='pageBtnImageLogo'
                        onClick={() => {
                            props.setShowMenu(!props.showMenu)
                        }}
                        src={state.user?.logoImg ?? '/logo192.png'}

                    />
                </div>
            </div>


        </div>
    )
}

const NavBar = withRouter(_NavBar)

export const Page = (props) => {

    const { state, dispatch } = React.useContext(GlobalContext)

    const [showMenu, setShowMenu] = React.useState(false)

    return (<div className='pageComponent'>

        <NavBar
            setShowMenu={setShowMenu}
            showMenu={showMenu}
        />

        <div className='pageChildren'>
            <Scrollbars>
                {props.children}
            </Scrollbars>
        </div>

        {showMenu &&
            <div className='pageMenu'>
                <Menu close={() => setShowMenu(false)} />
            </div>
        }

    </div>)

}