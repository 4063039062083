import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { api } from "./api";
import { GlobalContext } from "./providers/global";
import com from './constants'
import { NavigationProvider } from "./providers/navigation";
import Utils from "./utilities";
import loadUser from "./common/loadUser";

const initialState = {

    apiStatus: null,
    isAuthenticated: false,
    token: null,
    user: null,
    videoModal: {
        playing: false,
        minimized: true,
        left: 0,
        top: 400,
        width: window.innerWidth,
        height: 400,
    }

};

const reducer = (state, action) => ({ ...state, ...action })

const App = () => {

    const [state, dispatch] = React.useReducer(reducer, initialState);


    useEffect(() => {
        // (async () => {
        //     let apiStatus = await api.get('/health')
        //     console.log('apiStatus', apiStatus);
        //     dispatch({ apiStatus })
        // })()

        // load user
        loadUser(dispatch)

    }, [])

    // get user on first load
    // React.useEffect(() => {
    //     (async () => {

    //         const userId = localStorage.getItem('userId')
    //         if (!userId) return

    //         const user = await api.post(com.Endpoints.user.getById, { userId })
    //         if (user)
    //             dispatch({ user })
    //         else
    //             toast.error('Could not get user')

    //     })()
    // }, [])

    return (
        <GlobalContext.Provider value={{ state, dispatch }}>
            <NavigationProvider />
        </GlobalContext.Provider>
    );
}

export default App;