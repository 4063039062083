import React from 'react'
import { GlobalContext } from '../providers/global.js'
import Utils from '../utilities/index.js'

import { api } from '../api.js'

import '../styles/loginWidget.css'

const LoginWidget = (props) => {

    const { state, dispatch } = React.useContext(GlobalContext)

    const [name, setName] = React.useState('')
    const [password, setPassword] = React.useState('')

    const nameInputRef = React.createRef();

    const areInputsComplete = () => {
        return name.length > 0 && password.length > 0
    }

    React.useEffect(() => {

        nameInputRef.current.focus()

    }, [])

    const loginUser = async () => {

        // let user = await api.login({ name, password }, dispatch)
        let userRes = await api.post('/users/login', { name, password })
        console.log('loginUser', userRes);

        if (userRes?.token) {
            // dispatch({ user: userRes. })
            api.auth(userRes.token)
            window.location.reload()
            props.close()
        } else {
            window.alert('Unable to login')
        }
    }

    const renderCredentialInputs = () => (
        <div className='loginInputs'>
            <label>Name:</label>
            <input
                ref={nameInputRef}
                placeholder='name'
                type='text'
                value={name}
                onChange={e => {
                    console.log('setName', e.target.value);
                    setName(e.target.value)
                }
                }
                tabIndex={1}
            />
            <label>Password:</label>
            <input
                type='password'
                placeholder='password'
                value={password}
                onChange={e => {
                    console.log('setPassword', e.target.value);
                    setPassword(e.target.value)
                }
                }
                tabIndex={2}
            />
        </div>
    )

    const clearData = () => {
        setName('')
        setPassword('')
    }

    return (

        <div className='loginContainer'>

            {/* <div className='loginInputContainer'> */}
            <label htmlFor='name'>Name:</label>
            <input
                ref={nameInputRef}
                type='text'
                id='name'
                value={name}
                onChange={e => {
                    console.log('setName', e.target.value);
                    setName(e.target.value)
                }
                }
                tabIndex={1}
            />
            {/* </div> */}

            {/* <div className='loginInputContainer'> */}
            <label htmlFor='password'>Password:</label>
            <input
                type='password'
                id='password'
                value={password}
                onChange={e => {
                    console.log('setPassword', e.target.value);
                    setPassword(e.target.value)
                }}
                tabIndex={2}
            />
            {/* </div> */}
            {/* </div> */}

            <input
                className='loginClearBtn'
                type='button'
                value='Clear'
                onClick={clearData}
                tabIndex={4}
            />

            {areInputsComplete() && <input
                type='button'
                value='Login'
                onClick={loginUser}
                tabIndex={3}
            />}

        </div>
    )
}

export default LoginWidget