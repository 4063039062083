import React from 'react'
import HttpsRedirect from 'react-https-redirect'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import routes from '../routes'

import screens from '../screens'
import { Page } from '../components/page'
import { GlobalContext } from './global'
import { api } from '../api'

export const NavigationProvider = (props) => {

    const { state, dispatch } = React.useContext(GlobalContext)

    // redownload background image when changed
    // React.useEffect(() => {

    //     (async () => {
    //         let user = await api.fetchCurrentUserData()
    //         dispatch({ user })
    //     })()

    // }, [state.user?.logo])

    const getRoutes = () => {

        return routes.map((route, routeIdx) => {
            // if (routeIdx === 0) return null
            return <Route
                exact
                key={`route${routeIdx}`}
                path={route.path}
                component={route.component}
            />

        })

    }

    return (
        <HttpsRedirect>
            <BrowserRouter>
                <Page {...props}>
                    <Switch>
                        {getRoutes()}
                    </Switch>
                </Page>
            </BrowserRouter>
        </HttpsRedirect>
    )

}