import React from 'react'
import { isMobile } from "react-device-detect";
import { GlobalContext } from '../providers/global';

import '../styles/videoModal.css'

export default props => {

    const { state, dispatch } = React.useContext(GlobalContext)

    const [mouse, setMouse] = React.useState({
        down: false,
    })

    const vid = state.videoModal

    const pad = 40

    const getModalStyle = () => ({
        left: vid.minimized ? `${window.innerWidth}px` : `${vid.left}px`,
        top: vid.minimized ? `${window.innerHeight}px` : `${vid.top}px`,
        width: `${vid.width}px`,
        height: vid.src ? `${vid.height}px` : 'auto',
    })

    const getIconStyle = () => ({
        border: vid.minimized ? '' : '3px solid green',
    })

    const handleMouseDown = e => {
        let event = isMobile ? e.touches?.[0] : e
        if (!event) return

        setMouse({
            ...mouse,
            down: true,
            resize: event.target.className === 'videoModalResize',
            originalLeft: vid.left - event.clientX,
            originalTop: vid.top - event.clientY,
            clientX: event.clientX,
            clientY: event.clientY,
        })
    }

    const handleMouseMove = e => {

        let event = isMobile ? e.touches?.[0] : e
        if (!event || !mouse.down) return

        let modalState = { ...state.videoModal }

        if (!mouse.resize) {
            modalState.left = event.clientX + mouse.originalLeft
            modalState.top = event.clientY + mouse.originalTop
        } else {
            modalState.width = state.videoModal.width + (event.clientX - mouse.clientX)
            modalState.height = state.videoModal.height + (event.clientY - mouse.clientY)
        }

        setMouse({ ...mouse, clientX: event.clientX, clientY: event.clientY })
        dispatch({ videoModal: modalState })

    }

    const handleMouseUp = e => setMouse({ ...mouse, down: false })

    const handleMouseLeave = e => setMouse({ ...mouse, down: false })

    return (<>

        {/* Nav bar button */}
        <div
            className='videoModalIcon'
            style={getIconStyle()}
            onClick={() => {
                dispatch({
                    videoModal: {
                        ...state.videoModal,
                        minimized: !state.videoModal.minimized
                    }
                })
            }}
        ><img src='assets/icons/video_white.png' /></div>

        {/* modal */}
        <div
            className='videoModalPlayer'
            style={getModalStyle()}

            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseLeave}

            onTouchStart={handleMouseDown}
            onTouchMove={handleMouseMove}
            onTouchEnd={handleMouseUp}
            onTouchCancel={handleMouseLeave}
        >

            {vid.src && <>
                <div className='videoModalPlayerHeader'>
                    <h1 style={{ margin: 0 }}>{vid.name} - {vid.artist}</h1>
                    <p style={{ margin: 0 }}>{vid.description}</p>
                </div>

                {/* iframe */}
                {vid && <iframe
                    style={{ margin: 0 }}
                    key={vid.name + 'Frame'}
                    width={vid.width ?? '600'}
                    height={vid.height ?? '400'}
                    src={vid.src}
                    title={vid.title}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen={'allowFullScreen'}
                ></iframe>}

                <div className='videoModalResize' />

            </>}

        </div>
    </>)

}