import home from './home.js'
import math from './math.js'
import video from './video.js'

export default {

    home,
    math,
    video,

}