import React from "react"
import { withRouter } from "react-router-dom"
import { toast } from "react-toastify"
import { api } from "../api"
import { GlobalContext } from "../providers/global"
import { isMobile } from "react-device-detect";
// import utilities from "../utilities"

import '../styles/video.css'

import Utils from "../utilities/index.js"
import Constants from '../constants'
import CustomButton from "../components/customButton"

const { Embed } = Constants

const VideoScreen = props => {

    const { state, dispatch } = React.useContext(GlobalContext)

    const updateVideoModal = update => {
        dispatch({
            videoModal: {
                ...state.videoModal,
                ...update,
            }
        })
    }

    // make video visible
    React.useEffect(() => {
        updateVideoModal({ minimized: false })
    }, [])

    return (
        <div className='videoContainer'>

            <h1>
                Video is awesome!
            </h1>

            <div className='videoCarossel'>
                {Embed.youtube.map((yt, ytIdx) => {
                    return (
                        <CustomButton
                            key={`vidBtn${ytIdx}`}
                            text={<div
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',

                                }}
                            >
                                <h5>{yt.name}</h5>
                                <h6>{yt.artist}</h6>
                            </div>}
                            onClick={() => updateVideoModal(yt)}
                        />
                    )
                })}
            </div>

        </div >

    )
}

export default withRouter(VideoScreen)