import * as Endpoints from './endpoints.js'
import * as Colors from './colors'
import * as Embed from './embed'

export default {

    Endpoints,
    Colors,
    Embed,

}