import '../styles/customButton.css'

export default props => {
    return (
        <button
            className='customButtonContainer'
            onClick={props.onClick}
            key={props.key}
        >
            {props.text}
        </button>
    )
}