export const youtube = [
    {
        src: 'https://www.youtube.com/embed/7yJd-hUgGSU',
        name: 'Take One',
        artist: 'February Blue Moon',
        description: 'I made this for fun on Thanksgiving 2021',
    },
    {
        src: 'https://www.youtube.com/embed/N1LPPEpKveU',
        name: 'GOAT Drums',
        artist: 'Polyphia',
        description: 'From the drummer\'s perspective.',
    },
]