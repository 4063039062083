// import { LoginWidget } from './loginWidget.js'
import Menu from './menu'
import LoginWidget from './loginWidget'
import CustomButton from './customButton'

export default {

    Menu,
    LoginWidget,
    CustomButton,

}