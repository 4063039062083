import { api } from '../api.js'

const uploadFiles = async (dialog, endpoint, additionalBodyFields, callback) => {

    try {
        dialog.click();
        dialog.onchange = async (e) => {

            let formData = new FormData()

            for (const [key, value] of Object.entries(additionalBodyFields))
                formData.set(key, value)

            const files = e.composedPath()[0].files
            for (let file of files)
                formData.set('files', file)

            const res = await api.post(endpoint, formData)

            callback(res)

        };
    } catch {
        console.log('Error uploading files');
    }

}

const createUploadDialog = () => {

    let dialog = document.createElement('input');
    dialog.setAttribute('accept', '.jpg');
    dialog.setAttribute('type', 'file');
    // dialog.setAttribute('multiple', 'multiple');
    return dialog

}

const uploadSingleImage = async (endpoint, data, callback) => {

    const dialog = createUploadDialog()
    uploadFiles(dialog, endpoint, data, callback)

}

const downloadImage = async (endpoint, data, callback) => {

    const res = await api.download(endpoint, data)
    console.log('downloadImage', endpoint, res);
    await callback?.(res)
    return res

}

const imageUtils = {

    uploadFiles,
    downloadImage,
    uploadSingleImage,

}

export default imageUtils