import axios from 'axios'
import { toast } from 'react-toastify';

import https from 'https'

import Utils from './utilities'
import Constants from './constants'

const HTTP_VERBS = {
    get: 'get',
    post: 'post',
    patch: 'patch',
    delete: 'delete',
}

// axios.defaults.withCredentials = true;

let apiUrl = '', uiUrl = '';

if (window.location.href.includes('localhost') || window.location.href.includes('127.0.0.1')) {
    apiUrl = 'http://localhost:8080'
    uiUrl = 'http://localhost:3000'
}

if (window.location.href.includes('februarybluemoon') || window.location.href.includes('3.15.44.59')) {
    // apiUrl = 'https://3.136.97.58'
    apiUrl = 'https://api.februarybluemoon.com'
    uiUrl = 'https://februarybluemoon.com'
}

// apiUrl = 'https://api.februarybluemoon.com'

console.log('api url', apiUrl);

const getHeaders = () => {
    let headers = {}
    try {
        headers = JSON.parse(localStorage.getItem('AUTH'))
    } catch (e) {
        console.log('No auth headers found');
    }
    return headers
}

const handleError = (res, skipAuth = false) => {

    if (!res?.data) {
        console.log('No server response')
        toast.error('No server response')
        return
    }

    if (res.data.error) {
        console.log('Error:', res.data.error);
        toast.error('Error:', res.data.error);
        return
    }

    return res.data

}

const joinPath = (base, endpoint) => {

    let url;
    const baseLast = base[base.length] - 1 === '/'
    const endpointFirst = endpoint[0] === '/'
    if ((baseLast && endpointFirst))
        url = base + endpoint.slice(1)
    else if (!baseLast && !endpointFirst)
        url = base + '/' + endpoint
    else
        url = base + endpoint

    return url

}

const getUserId = () => {
    return localStorage.getItem('userId')
}

const saveUserId = (userId) => {
    localStorage.setItem('userId', userId)
}

const makeApiCall = (verb) => async (endpoint, data = {}, config = {}, skipAuth = false) => {
    
    const axConfig = {
        'Content-Type': 'application/x-www-form-urlencoded',
        headers: getHeaders(),
        ...config,
    }
    
    console.log('headers', axConfig);
    let res

    try {
        const url = joinPath(apiUrl, endpoint)
        if ([HTTP_VERBS.get, HTTP_VERBS.delete].includes(verb)) {
            res = await axios[verb](url, axConfig)
        } else {
            res = await axios[verb](url, data, axConfig)
        }
    }
    catch (err) {
        toast.error('Network error', err)
        return
    }

    return handleError(res, skipAuth)

}

// const fetchCurrentUserData = async (userId = null, skipAuth = false) => {

//     userId = userId ?? getUserId()

//     if (userId === 'null') {
//         localStorage.removeItem('userId')
//         return null
//     }
//     if (!userId) {
//         return null
//     }

//     let user = await makeApiCall(HTTP_VERBS.post)(Constants.Endpoints.user.getById, { userId })

//     if (user?.logo) {
//         let logo = await Utils.image.downloadImage('/users/downloadLogo', { userId })
//         user.logo = 'data:image/jpeg;base64,' + logo
//     }

//     return user

// }

export const api = {

    apiUrl,
    uiUrl,

    get: makeApiCall(HTTP_VERBS.get),

    post: makeApiCall(HTTP_VERBS.post),

    patch: makeApiCall(HTTP_VERBS.patch),

    delete: makeApiCall(HTTP_VERBS.delete),

    download: async (endpoint, data, skipAuth = false) => {

        console.log('download:', endpoint);
        let downloadRes = await makeApiCall(HTTP_VERBS.post)(endpoint, {
            ...data,
            responseType: 'blob'
        })
        console.log('downloadRes:', downloadRes);
        return downloadRes

    },

    // fetchCurrentUserData,

    // login: async (data, dispatch) => {

    //     console.log('loggin');
    //     let user = await makeApiCall(HTTP_VERBS.post)('user/login', data)

    //     if (!user) {
    //         alert('Unable to login')
    //         return
    //     }

    //     saveUserId(user._id)
    //     localStorage.setItem('loggedIn', true)

    //     user = await fetchCurrentUserData(user._id)
    //     console.log('fetchCurrentUserData login', user);


    //     return user

    // },

    logout: async (dispatch) => {

        let userId = localStorage.getItem('userId')

        await makeApiCall(HTTP_VERBS.post)('user/logout', { userId })

        console.log('logout');
        localStorage.removeItem('userId')
        localStorage.setItem('loggedIn', false)
        dispatch({ user: null })
    },

    auth: token => {
        let val = {
            Authorization: 'Bearer ' + token
        }
        localStorage.setItem('AUTH', JSON.stringify(val))
    }

}