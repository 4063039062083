import React from 'react'

import { GlobalContext } from '../providers/global'
import Utils from "../utilities/index.js"

import LoginWidget from '../components/loginWidget.js'

import '../styles/menu.css'
import { api } from '../api'
import Constants from '../constants'
import loadUser from '../common/loadUser'

const Menu = props => {

    const { state, dispatch } = React.useContext(GlobalContext)

    const menuRef = React.useRef()

    const [showLogin, setShowLogin] = React.useState(false)

    const handleClickOutside = (e) => {
        console.log('target', e.target?.id);
        const clickedIcon = ['pageBtnImageLogo'].includes(e.target?.id)
        if ((menuRef.current && !menuRef.current.contains(e.target)) && !clickedIcon) {
            props.close()
        }
    }

    const logoutOpt = {
        label: state.user ? 'Logout' : 'Login',
        action: () => {
            if (state.user) {
                api.logout(dispatch)
                props.close()
            } else {
                setShowLogin(true)
            }
        }
    }

    let options = []
    if (state.user) {
        options = [
            {
                label: 'Upload Logo',
                action: () => uploadLogo(),
            }
        ].concat(options)
    }

    const uploadLogo = async () => {

        Utils.image.uploadSingleImage('/users/uploadLogo', { userId: state.user._id }, async user => {
            if (!user) return
            loadUser(dispatch)
        })

    }

    React.useEffect(() => {

        document.addEventListener('click', handleClickOutside, true)

        return () => {
            document.removeEventListener('click', handleClickOutside, true)
        }

    }, [])

    return (
        <div className='menuComponent' ref={menuRef} style={{ backgroundColor: Constants.Colors.components.menu.backgroundColor }}>

            <h2>
                Welcome {state.user?.name ?? 'User'}!
            </h2>

            {options.map((opt, optIdx) => {
                return (
                    <div
                        className='menuButton'
                        onClick={opt.action} key={`menuOpt${optIdx}`}
                        style={{
                            marginBottom: '20px'
                        }}
                    >
                        {opt.label}
                    </div>
                )
            })}

            {showLogin ?
                <LoginWidget
                    close={props.close}
                />
                :
                <div className='menuButton' onClick={logoutOpt.action} key={`menuOptLog`}>
                    {logoutOpt.label}
                </div>

            }

        </div>
    )
}

export default Menu