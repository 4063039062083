import { api } from "../api";
import utilities from "../utilities";


const loadUser = async (dispatch) => {
    const user = await api.get('/users/self')
    if (user?.logo) {
        // let logo = await utilities.image.downloadImage('/users/downloadLogo', { userId: user._id })

        // let logo = await api.download('/users/downloadLogo')
        // let logo = await utilities.image.downloadImage('/users/downloadLogo')
        // console.log('getting logo', user.logo, logo);
        user.logoImg = 'data:image/jpeg;base64,' + user.logoImg
    }
    console.log('user:', user);
    dispatch({ user })
}

export default loadUser