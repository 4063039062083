import React, { useEffect } from "react"
import { toast } from "react-toastify"
import { api } from "../api"
import { GlobalContext } from "../providers/global"
// import utilities from "../utilities"

import '../styles/home.css'
import Utils from "../utilities/index.js"

import routes from "../routes"
import { withRouter } from "react-router-dom"
import CustomButton from "../components/customButton"

const Home = props => {

    const { state, dispatch } = React.useContext(GlobalContext)

    const getStyle = () => {
        return {
            '--backgroundImage': state.user?.logoImg ? 'url(' + state.user?.logoImg + ')' : null,
        }
    }

    return (<div className='homeContainer' style={getStyle()}>

        {routes.filter(r => r.onDash).map((route, routeIdx) => {
            return (
                <CustomButton
                    onClick={() => props.history.push(route.path)}
                    key={`homeLink${routeIdx}`}
                    text={route.name}
                />
                // <button
                //     className='homeDashLinkBtn'
                //     onClick={() => props.history.push(route.path)}
                //     key={`homeLink${routeIdx}`}
                // >
                //     {route.name}
                // </button>
            )
        })}

    </div >)
}

export default withRouter(Home)