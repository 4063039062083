import React from "react"
import { withRouter } from "react-router-dom"
import { toast } from "react-toastify"
import { api } from "../api"
import { GlobalContext } from "../providers/global"
// import utilities from "../utilities"

import '../styles/math.css'
import Utils from "../utilities/index.js"

const Math = props => {

    const { state, dispatch } = React.useContext(GlobalContext)

    return (<div className='mathContainer'>

        <h1>
            Math is awesome!
        </h1>

    </div >)
}

export default withRouter(Math)