import screens from './screens/index.js'

export default [
    {
        path: '/',
        name: 'Home',
        component: screens.home,
        onDash: true,
    },
    {
        path: '/video',
        name: 'Video',
        component: screens.video,
        onDash: true,
    },
    {
        path: '/math',
        name: 'Math',
        component: screens.math,
        onDash: true,
    }
]